<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Avans İade Nedenleri"
            icon="mdi-cash-refund"
            :add-route="
              can('edit-payback-reason')
                ? {
                    name: 'other-definitions.payback-reasons.create',
                  }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-payback-reason')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            @click:edit="handleEditClick"
            :show-delete="can('delete-payback-reason')"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleBatchDeleteClick"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'other-definitions.payback-reasons.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:-item.name="{ item }">
          <router-link
            :to="{
              name: 'other-definitions.payback-reasons.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <rs-table-button-delete
            @deleted="handleDelete(item)"
            :loading="isLoading"
          />
        </template>
      </v-data-table>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import OptionsService from "@/core/services/options.service";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-payback-reason"),
        },
        {
          text: "Avans İade Nedeni",
          value: "name",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`/payback-reasons/`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "other-definitions.payback-reasons.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleDelete() {
      this.$api
        .delete(`payback-reasons/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Avans iade nedeni silindi.");
          OptionsService.getGenericList();
          this.$nextTick(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} avans iade nedenini silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      this.handleDelete();
      this.$refs.confirmDelete.hide();
      this.loadList();
    },
  },
};
</script>
